<template>
  <loading v-if="$_.isEmpty(invoiceData)" />
  <div v-else>
    <div class="columns is-marginless">
      <div
        class="column is-6 is-flex is-flex-column has-content-justified-between has-items-aligned-start"
      >
        <img
          src="/assets/images/interface/logos/fixed.svg"
          class="is-block"
          style="height: 40px; width: auto;"
        />
        <p>
          <strong>Invoice #{{ invoiceData.invoiceNum }}</strong>
        </p>
      </div>

      <div class="column is-6 is-12-mobile has-text-right has-text-left-mobile">
        <component :is="'p'" class="has-white-space-pre-line">{{
          invoiceData.billingEntity.address
        }}</component>
      </div>
    </div>

    <hr class="has-margin-y-75" />

    <div class="columns is-multiline is-marginless">
      <div class="column is-6">
        <p>
          <strong>{{ invoiceData.email }}</strong>
        </p>
        <div v-if="company">
          <p v-if="!$_.isEmpty(company.name)">{{ company.name }}</p>
          <p v-if="!$_.isEmpty(company.address.line1)">
            {{ company.address.line1 }},
          </p>
          <p v-if="!$_.isEmpty(company.address.line2)">
            {{ company.address.line2 }},
          </p>
          <p
            v-if="
              !$_.isEmpty(company.address.city) ||
                !$_.isEmpty(company.address.postcode)
            "
          >
            <span v-if="!$_.isEmpty(company.address.city)"
              >{{ company.address.city }},</span
            >
            <span v-if="!$_.isEmpty(company.address.postcode)">
              {{ company.address.postcode }}
            </span>
          </p>
          <p v-if="!$_.isEmpty(company.address.country.name)">
            {{ company.address.country.name }}
          </p>
          <p v-if="!$_.isEmpty(company.vatNumber)" class="is-size-8">
            VAT#: {{ company.vatNumber }}
          </p>
        </div>
      </div>

      <div class="column is-6 is-12-mobile has-text-right has-text-left-mobile">
        <p>
          <strong>
            Issued
            {{
              $moment(invoiceData.dateCreated.toDate()).format("MMMM Do, YYYY")
            }}
          </strong>
        </p>
        <p>
          Paid
          {{ $moment(invoiceData.datePaid.toDate()).format("MMMM Do, YYYY") }}
        </p>
        <p>Currency {{ invoiceData.currency.toUpperCase() }}</p>
        <button
          v-if="!isPrinting"
          class="button is-dark is-outlined is-rounded has-margin-top-100"
          @click="print"
        >
          Print invoice
        </button>
      </div>
    </div>

    <table class="table table-full-width has-margin-bottom-100">
      <thead>
        <tr>
          <th colspan="2">Items</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in invoiceData.items" :key="item.description">
          <td>{{ item.description }}</td>
          <td class="has-white-space-nowrap has-text-right" style="width:1%;">
            {{ $formatCurrency(item.total, invoiceData.currency) }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="columns is-marginless">
      <div class="column is-6">
        <b-message v-if="transaction" type="is-success">
          <status-label :status="invoiceData.status" />
          Payment for this invoice was taken
          {{ $moment(invoiceData.datePaid.toDate()).from() }}, with a
          {{ transaction.source.brand }} ending {{ transaction.source.last4 }}.
          Your transaction ID is
          <strong>{{ invoiceData.transactionRef.id }}</strong>
          .
        </b-message>
      </div>

      <div class="column is-5 is-offset-1 is-12-mobile">
        <div class="totals">
          <apart v-for="summary in totalsSummary" :key="summary.label">
            <span>
              {{ summary.label }}
              <b-tooltip
                v-if="summary.tooltip"
                :label="summary.tooltip"
                type="is-info"
                multilined
              >
                <b-icon icon="info-circle" size="is-small" type="is-info" />
              </b-tooltip>
            </span>

            <strong>{{ summary.value }}</strong>
          </apart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invoice",
  props: {
    invoiceId: {
      required: false,
      type: String,
      default: ""
    },
    invoice: {
      required: false,
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      loading: true,
      observeCount: 0
    };
  },
  computed: {
    invoiceData() {
      return (
        this.invoice || this.$store.getters["invoices/invoice"](this.invoiceId)
      );
    },
    company() {
      return this.invoiceData.company || null;
    },
    transaction() {
      return this.$store.getters["transactions/transaction"](
        this.$_.get(this.invoiceData, "transactionRef.id")
      );
    },
    totalsSummary() {
      const summary = [],
        { tax } = this.invoiceData;
      if (!!tax.jurisdiction || !!tax.total) {
        const { country } = this.$tax(tax.total, tax.jurisdiction);
        summary.push({
          label: `Subtotal`,
          value: this.$formatCurrency(
            this.invoiceData.subtotal,
            this.invoiceData.currency
          )
        });
        summary.push({
          label: `Tax at ${tax.rate}%`,
          value: `${this.$formatCurrency(
            tax.total,
            this.invoiceData.currency
          )}`,
          tooltip: `As your card is registered to an address within ${country}, ${tax.rate}% VAT has been applied to your order.`
        });
      }
      summary.push({
        label: `Total`,
        value: this.$formatCurrency(
          this.invoiceData.total,
          this.invoiceData.currency
        ),
        class: "has-text-info"
      });
      return summary;
    },
    isPrinting() {
      return this.$route.path.startsWith("/print/");
    }
  },
  watch: {
    invoiceData(data) {
      if (this.$_.has(data, "userId")) {
        this.invoiceLoaded();
      }
    }
  },
  async created() {
    if (!this.invoice) {
      await this.$store
        .dispatch("invoices/observeInvoice", {
          invoiceId: this.invoiceId
        })
        .catch(() => {
          this.$emit("error");
        });
      if (!this.$_.isEmpty(this.invoiceData)) {
        this.invoiceLoaded();
      }
    }
  },
  beforeDestroy() {
    this.$_.range(0, this.observeCount).forEach(() => {
      this.$store.dispatch("transactions/unobserveTransaction", {
        transactionId: this.invoiceData.transactionRef.id
      });
    });

    if (!this.invoice) {
      this.$store.dispatch("invoices/unobserveInvoice", {
        invoiceId: this.invoiceId
      });
    }
  },
  methods: {
    invoiceLoaded() {
      this.$emit("loaded");
      this.getTransaction();
    },
    getTransaction() {
      if (!!this.invoiceData && !!this.invoiceData.transactionRef) {
        this.observeCount++;
        this.$store.dispatch("transactions/observeTransaction", {
          transactionId: this.invoiceData.transactionRef.id
        });
      }
    },
    print() {
      const invoiceId = this.invoiceId || this.invoice._id;
      this.$router.push(`/print/invoice/${invoiceId}`);
      this.$emit("print");
    }
  }
};
</script>
